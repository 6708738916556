import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Introduction to what this document is`}</em></p>
    <h2>{`Styles and Tokens`}</h2>
    <p><em parentName="p">{`What type of styles do we have deefined? Colors, containers, typography`}</em></p>
    <p><em parentName="p">{`What are the tokens? How do I use them`}</em></p>
    <h2>{`Patterns`}</h2>
    <h2>{`Design Specifications with Capital`}</h2>
    <p><em parentName="p">{`Figma library`}</em></p>
    <p><em parentName="p">{`How to label a component in a specification`}</em></p>
    <p>{`Building a new component? See the contribution guidelines.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      